export const nav = [
  {
    text: "home",
    path: "/",
  },
  // {
  //   text: "about",
  //   path: "/about",
  // },
  {
    text: "categories",
    path: "/services",
  },
  {
    text: "events",
    path: "/blog",
  },
  // {
  //   text: "pricing",
  //   path: "/pricing",
  // },
  {
    text: "contact",
    path: "/contact",
  },
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Concerts",
    total: "1298",
  },
  {
    cover: "../images/hero/h2.png",
    name: "Workshops",
    total: "472",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Corporate Events",
    total: "87",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Conferences",
    total: "89",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Online Events",
    total: "268",
  },
]
export const list = [
  {
    id: 1,
    cover: "../images/list/event-1.jpg",
    name: "Toronto day out",
    location: "1100 Toronto Rod NW",
    category: "In Person",
    price: "$45",
    type: "Networking",
  },
  {
    id: 2,
    cover: "../images/list/event-2.jpg",
    name: "Talent Discussion, Montreal",
    location: "0101 Montreal Rod E",
    category: "In Person",
    price: "$100",
    type: "Educational",
  },
  {
    id: 3,
    cover: "../images/list/event-1.jpg",
    name: "Music Concert, Mississauga",
    location: "7855 Grande E",
    category: "In Person",
    price: "$59",
    type: "Concert",
  },
  {
    id: 4,
    cover: "../images/list/event-2.jpg",
    name: "Herringbone Realty",
    location: "Tulip Festival, Ottawa",
    category: "In Person",
    price: "$187",
    type: "Festival",
  },
  {
    id: 5,
    cover: "../images/list/event-1.jpg",
    name: "Startup Minds Meetup",
    location: "67 Noth Toronto, Canada",
    category: "Remote",
    price: "$129",
    type: "Meetup",
  },
  {
    id: 6,
    cover: "../images/list/event-2.jpg",
    name: "HR Forum",
    location: "4689 Helok Rd N",
    category: "Remote",
    price: "$20",
    type: "Networking",
  },
]
export const awards = [
  {
    icon: <i class='fa-solid fa-trophy'></i>,
    num: "32 M	",
    name: "Blue Burmin Award",
  },
  {
    icon: <i class='fa-solid fa-briefcase'></i>,
    num: "43 M",
    name: "Mimo X11 Award",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "51 M",
    name: "Australian UGC Award",
  },
  {
    icon: <i class='fa-solid fa-heart'></i>,
    num: "42 M",
    name: "IITCA Green Award",
  },
]
export const location = [
  {
    id: 1,
    name: "Toronto, Ontario",
    noofevents: "12",
    cover: "./images/location/event-1.jpg",
  },
  {
    id: 2,
    name: "Oakville, Ontario",
    noofevents: "24",
    cover: "./images/location/event-2.jpg",
  },
  {
    id: 3,
    name: "Montreal, Quebec",
    noofevents: "45",
    cover: "./images/location/event-1.jpg",
  },
  {
    id: 4,
    name: "Brampton, Ontario",
    noofevents: "5",
    cover: "./images/location/event-2.jpg",
  },
  {
    id: 5,
    name: "Hamilton, Ontario",
    noofevents: "99",
    cover: "./images/location/event-1.jpg",
  },
  {
    id: 6,
    name: "Florida, USA",
    noofevents: "65",
    cover: "./images/location/event-2.jpg",
  },
]
export const team = [
  {
    list: "50",
    cover: "../images/customer/team-1.jpg",
    address: "Liverpool, Canada",
    name: "Sargam S. Singh",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "70",
    cover: "../images/customer/team-2.jpg",
    address: "Montreal, Canada",
    name: "Harijeet M. Siller",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "80",
    cover: "../images/customer/team-3.jpg",
    address: "Denever, USA",
    name: "Anna K. Young",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "51",
    cover: "../images/customer/team-4.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "42",
    cover: "../images/customer/team-5.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "38",
    cover: "../images/customer/team-5.jpg",
    address: "Montreal, USA",
    name: "Adam K. Jollio",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
]
export const price = [
  {
    plan: "Basic",
    price: "29",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Standard",
    price: "49",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Platinum",
    price: "79",
    ptext: "2 user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
]
export const footer = [
  {
    title: "Pages",
    text: [{ list: "Home" }, { list: "Categories" }, { list: "Events" }, { list: "Contact" }],
  },
  {
    title: "Sections",
    text: [{ list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }],
  },
  {
    title: "EventMart",
    text: [{ list: "About" },{ list: "Login" }],
  },
]
